<template>
  <div>
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          shaped
          top
          v-model="snackbarStatus"
          :timeout="snackbarTimeout"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <h3>سفارشات شما</h3>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-data-table
            align="center"
            :headers="headers"
            :items="userOrders.data"
            :items-per-page="10"
            class="elevation-5"
            hide-default-footer>
          <template v-slot:item.status="{ item }">
            <v-btn
                :color="getColor(item.status)"
                v-text="getText(item.status)"
                dark
            >
              {{ item.status }}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12">
        <v-pagination
            color="#161853"
            v-model="userOrders.current_page"
            :length="userOrders.last_page"
            circle
            @input="getUserOrder"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Orders",
  data() {
    return {
      userOrders: [],
      userDiscount: [],
      headers: [
        {text: 'شناسه', value: 'id', align: 'center'},
        {text: 'کد', value: 'code', align: 'center'},
        {text: 'مبلغ', value: 'amount', align: 'center'},
        {text: 'مبلغ بدون تخفیف', value: 'amount_without_discount', align: 'center'},
        {text: 'وضعیت', value: 'status', align: 'center'},
        {text: 'کد تخفیف', value: 'discount.code', align: 'center'},
      ],
    }
  },
  methods: {
    getUserOrder() {
      let page = this.userOrders.current_page ?? 1
      window.axios
          .get('/user/orders/index?page=' + page)
          .then((res) => {
            this.userOrders = res.data.data;
          })
          .catch((err) => {
            console.log(err)
          })
    },
    getColor (status) {
      if (status === 'failed') return 'red'
      else if (status === 'pending') return 'orange'
      else return 'green'
    },
    getText (status) {
      if (status === 'failed') return 'ناموفق'
      else if (status === 'pending') return 'نامعلوم'
      else return 'موفق'
    },
  },

  mounted() {
    this.getUserOrder()
  }
}
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default{
  width: 80px;
}
</style>